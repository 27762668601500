<template>
  <div class="main-conatiner">
    <govstack-ribbon application-name="Citizen Portal" :token-claims="tokenClaims"></govstack-ribbon>
    <Header />
    <SubHeader />
    <main>
      <LoaderIcon v-if="loading" />
      <router-view v-else />
    </main>
    <Footer />
    <v-idle @idle="onidle" :loop="true" :wait="5" :duration="IDLETIMEOUT" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Header from './Header.vue'
import SubHeader from './SubHeader.vue'
import Footer from './Footer.vue'
import { IDLETIMEOUT } from '../../utilities/constants'
import LoaderIcon from '@/assets/svg/loader.svg'
export default {
  name: 'Layout',
  data() {
    return {
      IDLETIMEOUT: IDLETIMEOUT,
      loading: true,
    }
  },
  components: {
    Header,
    SubHeader,
    Footer,
    LoaderIcon,
  },
  computed: {
    tokenClaims() {
      return this.userProfile ? JSON.stringify(this.userProfile) : ''
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      userProfile: (state) => state.common.userProfile,
    }),
  },
  created() {
    this.getLoggedInDetails()
  },
  methods: {
    getLoggedInDetails() {
      if (this.userRole === 'SysAdmin' || this.userRole === 'Admin') {
        this.loading = true
        this.$store
          .dispatch('common/getAdminTenants')
          .then(() => {
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    onidle() {
      this.$store.dispatch('common/signOut')
    },
  },
  watch: {
    userRole(newValue, oldValue) {
      this.getLoggedInDetails()
    },
  },
}
</script>
<style lang="scss" scoped></style>
